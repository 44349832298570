import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.is-array.js";
import "core-js/modules/es.array.map.js";
import SignaturePad from 'signature_pad';
import { defineComponent } from 'vue-demi';
export default defineComponent({
  props: {},
  data: function data() {
    return {
      overlayDrawPads: null,
      hideDrawMenu: true,
      drawColors: ['#000000', '#ff0000', '#00ff00', '#0000ff', '#ff00ff'],
      drawWidths: [6, 4, 2],
      selectedDrawColor: '',
      selectedDrawWidth: 1
    };
  },
  components: {},
  mounted: function mounted() {},
  watch: {
    selectedDrawColor: function selectedDrawColor() {
      var _this = this;

      var _a;

      (_a = this.overlayDrawPads) === null || _a === void 0 ? void 0 : _a.forEach(function (pad) {
        pad.penColor = _this.selectedDrawColor;

        if (!_this.selectedDrawColor) {
          pad.minWidth = 0;
          pad.maxWidth = 0;
          pad.off();
        } else {
          pad.on();
        }
      });
    },
    selectedDrawWidth: function selectedDrawWidth() {
      var _this2 = this;

      var _a;

      (_a = this.overlayDrawPads) === null || _a === void 0 ? void 0 : _a.forEach(function (pad) {
        pad.minWidth = _this2.selectedDrawWidth - 1;
        pad.maxWidth = _this2.selectedDrawWidth + 1;

        if (_this2.selectedDrawWidth === 0) {
          pad.minWidth = 0;
          pad.maxWidth = 0;
        }
      });
    }
  },
  methods: {
    initOverlayCanvas: function initOverlayCanvas() {
      var _a;

      var pdfFrames = (_a = this.$parent) === null || _a === void 0 ? void 0 : _a.$refs.pdfFrame;

      if (!Array.isArray(pdfFrames)) {
        pdfFrames = [pdfFrames];
      }

      this.overlayDrawPads = pdfFrames.map(function (pdfFrame) {
        pdfFrame.width = pdfFrame.clientWidth;
        pdfFrame.height = pdfFrame.clientHeight;
        return new SignaturePad(pdfFrame, {
          penColor: 'blue'
        });
      });
    },
    getOverlayDrawPngs: function getOverlayDrawPngs() {
      if (!this.overlayDrawPads) {
        return undefined;
      }

      return this.overlayDrawPads.map(function (overlay) {
        return overlay.toDataURL();
      });
    },
    clearAll: function clearAll() {
      var _a;

      (_a = this.overlayDrawPads) === null || _a === void 0 ? void 0 : _a.forEach(function (overlay) {
        return overlay.clear();
      });
    }
  }
});