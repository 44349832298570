export function getCroppedCanvasImage(canvas: HTMLCanvasElement, paddingWidth = 0, paddingHeight = 0) {
  let originalCtx = canvas.getContext('2d')!;

  let originalWidth = canvas.width;
  let originalHeight = canvas.height;
  let imageData = originalCtx.getImageData(0, 0, originalWidth, originalHeight);

  let minX = originalWidth + 1;
  let maxX = -1;
  let minY = originalHeight + 1;
  let maxY = -1;
  let x = 0;
  let y = 0;
  let currentPixelColorValueIndex;

  for (y = 0; y < originalHeight; y++) {
    for (x = 0; x < originalWidth; x++) {
      currentPixelColorValueIndex = (y * originalWidth + x) * 4;
      let currentPixelAlphaValue = imageData.data[currentPixelColorValueIndex + 3];
      if (currentPixelAlphaValue > 0) {
        if (minX > x) {
          minX = x;
        }
        if (maxX < x) {
          maxX = x;
        }
        if (minY > y) {
          minY = y;
        }
        if (maxY < y) {
          maxY = y;
        }
      }
    }
  }

  minX -= paddingWidth;
  maxX += paddingWidth;
  minY -= paddingHeight;
  maxY += paddingHeight;

  let croppedWidth = maxX - minX;
  let croppedHeight = maxY - minY;
  let cuttedImageData = originalCtx.getImageData(minX, minY, croppedWidth, croppedHeight);

  let croppedCanvas = document.createElement('canvas');
  let croppedCtx = croppedCanvas.getContext('2d')!;

  croppedCanvas.width = croppedWidth;
  croppedCanvas.height = croppedHeight;
  croppedCtx.putImageData(cuttedImageData, 0, 0);

  return croppedCanvas.toDataURL();
}
