export interface ZipCodeInfoPlace {
  'place name': string;
  longitude: string;
  state: string;
  'state abbreviation': string;
  latitude: string;
}

export interface ZipCodeInfo {
  'post code': string;
  country: string;
  'country abbreviation': string;
  places: ZipCodeInfoPlace[];
}

export function getZipCodeInfo(zipCode: string, country = 'de'): Promise<ZipCodeInfoPlace> {
  return fetch(`https://api.zippopotam.us/${country}/${zipCode}`)
    .then(response => response.json())
    .then((data: ZipCodeInfo) => {
      const { places } = data;
      return places[0];
    });
}

export function getZipCodeCity(zipCode: string, country = 'de'): Promise<string> {
  return getZipCodeInfo(zipCode, country).then(info => info['place name']);
}
